import React from "react";
import {Button, Card, CardBody, CardFooter, Heading, Image, Stack, Text} from "@chakra-ui/react";


interface Contents{
    title: string;
    description: string;
    path: string;
    image: any;

}


function StepCard({title,description,path,image}:Contents){
    return (
        <>
            <Card
                direction="column"
                overflow='hidden'
                variant='outline'
                width = {{base: "80vw", md:"15vw"}}
                height = {{base: "130vw", md:"22vw"}}
                fontFamily = "Roboto"
                bg = "antiquewhite"

            >
                {image}

                <CardBody >
                    <Heading mb={"10px"} size='md' align = "start">{title}</Heading>
                    <Text fontSize = "20px" align = "start">{description}</Text>
                </CardBody>
            </Card>

        </>
    )
}

export default StepCard;