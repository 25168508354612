import React, { useState, useEffect } from 'react';
import {HStack, IconButton, Image, Text, Badge, Box, Heading, Button, Divider, Center, Card} from "@chakra-ui/react";
import img9 from "../gallery/9.jpg";

interface Content {
    image: any;
}

function ImageCard({image}:Content){
    return (
        <>
            <Image src={image} width = "100%" height = "100%" />
        </>
    )
}

export default ImageCard;