import React, {useState} from "react"
import {Box, Button, HStack, Image, SimpleGrid, Text} from "@chakra-ui/react";
import test0 from "../gallery/5.jpg"
import test1 from "../gallery/11.jpg"
import test2 from "../gallery/13.jpg"
import test3 from "../gallery/10.jpg"
import test4 from "../gallery/9.jpg"
import test5 from "../gallery/7.jpg"
import test6 from "../gallery/8.jpg"
import test7 from "../gallery/cover_2.jpg"
import test8 from "../gallery/35.jpg"
import {animated, useSpring} from "react-spring";
import {useEffect} from "react";
function CoverImages(){

    const AnimatedElement = ({ delay, children }) => {
        const [isVisible, setIsVisible] = useState(false);
        const props = useSpring({
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateY(0)' : 'translateY(-10px)',
            config: { duration: 500 },
            delay
        });

        useEffect(() => {
            setIsVisible(true);
        }, []);

        return <animated.div style={props}>{children}</animated.div>;
    };

    const paths =[test0,test1,test2,test3,test4,test5,test6,test7,test8
    ]
    const images=[];
    const time_gap=480;

    const image_size = {base: "28vw", md: "10vw"}

    paths.forEach(
        (item, index )=>{
            images.push(
                <Image src = {item} w = {image_size} h={image_size}/>
            )
        })

    return(
        <>
            <Box align={"start"}>


                <SimpleGrid columns={3} spacing={{base: "5px", md:"10px"}}>
                    {
                        images.map((item,index)=>

                                <AnimatedElement delay = {index*time_gap}>
                                    {item}
                                </AnimatedElement>

                        )
                    }
                </SimpleGrid>
                <AnimatedElement delay = {10*time_gap}>
                    <Button size = {{base:"sm",md:"md"}} bg={"#CA494E"} mt={"10px"} align={"left"}>View more in our gallery</Button>
                </AnimatedElement>

            </Box>
        </>

    )
}

export default CoverImages;