import React, { useState, useEffect } from 'react';
import {Button, Menu, MenuButton, MenuItem, MenuList, useDisclosure} from "@chakra-ui/react";
import {ChevronDownIcon} from "@chakra-ui/icons";


interface Content{
    text: string;
    clickfunc: any;
    items: any;
}

function NavBarMenu({text, clickfunc, items}: Content){
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            <Menu isOpen={isOpen} offset={[0,0]}>
                <MenuButton bg="none" h={"100%"}  onMouseEnter={onOpen} fontSize = {window.innerWidth > 768?"0.8vw":"14px"}
                            onMouseLeave={onClose}  borderRadius= "0" as={Button} rightIcon={<ChevronDownIcon />}>
                    {text}
                </MenuButton>
                <MenuList onMouseLeave={onClose} onMouseEnter={onOpen}>
                    { items&&
                        items.map((item,index)=>
                            <MenuItem onClick={()=>clickfunc(item)}>{item}</MenuItem>)
                    }
                </MenuList>
            </Menu>
        </>
    )

}

export default NavBarMenu;