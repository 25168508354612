import logo from './logo.svg';
import './App.css';

import {
  ChakraProvider,
  ColorModeScript,
  Flex,
  Grid,
  GridItem,
  HStack,
  Link,
  useToast,
  VisuallyHidden,
} from '@chakra-ui/react'
import React, {useRef, useState} from 'react';
import {
  Box,
  Button,
  Card,
  CardBody, CardFooter,
  Divider,
  Editable, EditableInput,
  EditablePreview,
  Heading, Input,
  InputGroup,
  Text,
  Textarea, VStack,
} from "@chakra-ui/react";
import theme from "./theme";
import Coverpage from "./Sections/Coverpage";
import SideNavigation from "./Sections/SideNavigation";
import Navbar from "./Sections/Navbar";
import Contact from "./Sections/Contact";
import Gallery from "./Sections/Gallery";
import Services from "./Sections/Services";
import HowToOrder from "./Sections/HowToOrder";
import About from "./Sections/About";


function App() {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }

  const service_ref = useRef(null);
  const gallery_ref = useRef(null);

  function scrollto(value){
    let targetElement = document.getElementById(value); // 获取目标锚点元素
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth',block: 'center'}); // 滚动到目标元素所在位置
    }
    //if (ref)
      //ref.current.scrollIntoView({ behavior: 'smooth' });
  }

  const margin_style = {base: "100vw" , md:"90vw"}



  return(
      <>
        <ChakraProvider>
          <Box align={"center"}>
            <VStack className={"wholepage"} w = {margin_style} >
              <Navbar scrollfunc={scrollto} />
              <Coverpage />
              <div id = "about"><About/></div>

              <Divider />
              <div id = "services">
                <Services  />
              </div>
              <Divider/>
              <HowToOrder />
              <Divider/>
              <div id={"gallery"}>
                <Gallery />
              </div>
              <Divider/>
              <div id = {"contact"}>
                <Contact />
              </div>
            </VStack>
          </Box>
        </ChakraProvider>
    </>

  );
}

export default App;
